.menu
  position: relative
  overflow: visible
  &__container
    border-radius: 6px
    backdrop-filter: blur(3px)
    right: 10px
    top: 10px
    overflow: hidden
    position: absolute
    z-index: 2
