@import "src/variables"

.telegram
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: center
  &__content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  &__description
    color: $GRAY_7
    font-size: 14px
    max-width: 80%
    text-align: center
  &__status
    font-size: 26px
    color: $WHITE
    margin-bottom: 6px
