@import "src/variables"
label
  cursor: pointer
  display: flex
  align-items: center
  padding: 10px 0
  color: $WHITE
  font-size: 16px
  input[type="radio"]
    --background: $TRANSPARENT
    --text: #FFFFFF
    --radio: #FFFFFF
    --radio-checked: #FEDD2C
    --radio-size: 26px
    --width: 100px
    --height: 140px
    --border-radius: 10px
    -webkit-appearance: none
    -moz-appearance: none
    position: relative
    height: var(--radio-size)
    width: var(--radio-size)
    outline: none
    margin: 0 10px 0 0
    cursor: pointer
    border: 2px solid var(--radio)
    background: transparent
    border-radius: 50%
    display: grid
    justify-self: end
    justify-items: center
    align-items: center
    overflow: hidden
    transition: border .5s ease
    &::before,
    &::after
      content: ""
      display: flex
      justify-self: center
      border-radius: 50%
    &::before
      position: absolute
      width: 100%
      height: 100%
      background: var(--background)
      z-index: 1
      opacity: var(--opacity, 1)
    &::after
      position: relative
      width: calc(100% /2)
      height: calc(100% /2)
      background: var(--radio-checked)
      top: var(--y, 100%)
      transition: top .5s cubic-bezier(0.48, 1.97, 0.5, 0.63)
    &:checked
      --radio: var(--radio-checked)
      &::after
        --y: 0%
        animation: stretch-animate .3s ease-out .17s
      &::before
        --opacity: 0
      ~ input[type="radio"]
        &::after
          --y: -100%
    &:not(:checked)
      &::before
        --opacity: 1
        transition: opacity 0s linear .5s




