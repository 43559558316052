@import "src/variables"

.cards-wrapper
  width: 100%
  height: 100vh
  background: $BLACK_1
  position: relative
  overflow: hidden
  &__content
    position: absolute
    z-index: 2
    width: 100%
    height: 100%
  &__card
    position: absolute
    width: 400px
    z-index: 1
    &--left
      left: -110px
      top: -150px
      transform: rotate(-150deg)
    &--right
      right: -110px
      bottom: -150px
      transform: rotate(20deg)
