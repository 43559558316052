@import "src/variables"

.contextmenu
  z-index: 1
  .container
    width: auto
    min-width: 200px
    border-radius: 6px
    height: auto
    backdrop-filter: blur(3px)
    display: flex
    flex-direction: column
    padding: 10px 0
    animation: show 450ms ease
  .contextmenu-item
    font-size: 16px
    text-decoration: none
    padding: 10px 15px
    cursor: pointer
    user-select: none
    transition: .2s
    position: relative
    margin-bottom: 2px
    font-weight: 500
    display: flex
    align-items: center
    outline: none
    color: $GRAY_6
    &__icon
      display: flex
      align-items: center
      margin-right: 12px
    &:hover
      background: rgba(67, 67, 67, 0.7)

@keyframes show
  from
    transform: scale(0.6) translate(-30px, -30px)
  to
    transform: scale(1) translate(0, 0)
