$WHITE: #FFFFFF
$BLACK: #000000
$BLACK_1: #141414
$GRAY_5: #D9D9D9
$GRAY_6: #BFBFBF
$GRAY_7: #8C8C8C
$GRAY_8: #595959
$GRAY_9: #434343
$GRAY_10: #262626
$YELLOW: #FEDD2C
$YELLOW_2: #D99236
$GREEN_1: #27ae60
$GREEN_2: #255A4A
$GREEN_6: #66BB6A
$OCEAN: #0F5767
$RED: #FA541C
$TRANSPARENT: transparent

@mixin rounded ($size, $background)
  width: $size
  height: $size
  background: $background
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
