@import "src/variables"

.stats
  width: 80%
  min-height: 120px
  height: 12%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  border-radius: 6px
  &__progress
    height: 100%
    width: 70px
    position: absolute
    z-index: 1
    bottom: 0
    animation: slide-bottom 450ms ease
  &__results
    width: auto
    height: 100%
    display: flex
    flex-direction: row
    align-items: center
  &__card
    height: 100px
    width: 70px
    border-radius: 6px
    display: flex
    align-items: center
    margin-left: 12px
    justify-content: center
    overflow: hidden
    position: relative
    span
      color: $WHITE
      font-size: 18px
      font-weight: 700
      z-index: 2

.average
  height: 140px
  width: 110px
  display: flex
  border-radius: 6px
  align-items: center
  flex-direction: column
  justify-content: center
  background: red
  &__radius
    width: 70px
    height: 70px
    border-width: 3px
    border-style: solid
    border-radius: 70px
    display: flex
    align-items: center
    justify-content: center
  &__title
    color: $WHITE
    display: block
    font-size: 18px
    margin-top: 8px
    font-weight: 700
  &__value
    color: $WHITE
    font-size: 21px
    font-weight: 700

@keyframes slide-bottom
  from
    transform: translateY(50px)
  to
    transform: translateY(0)
