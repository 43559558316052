@import "src/variables"

.tasks
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  padding-top: 55px
  &__content
    padding-top: 50px
    display: flex
    width: 100%
    height: 95%
    flex-direction: column
    overflow: scroll
    overflow-x: hidden
  &__footer
    display: flex
    width: 93%
    position: relative
  &__edit
    position: absolute
    top: -65px
    z-index: 10
    width: 100%
    border-radius: 6px
    height: 55px
    display: flex
    padding-left: 18px
    padding-right: 6px
    align-items: center
    background-color: red
    span
      width: 100%
      margin: 0 12px
      display: inline-block
      color: $WHITE
      font-size: 14px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

.task
  display: flex
  width: 100%
  border-radius: 6px
  justify-content: space-between
  flex-direction: row
  margin-bottom: 16px
  padding: 24px 16px
  position: relative
  &__result
    top: -10px
    right: 0
    position: absolute
    width: 30px
    height: 30px
    padding: 3px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    border: 1px $YELLOW solid
    span
      color: $YELLOW
      font-size: 12px
      font-weight: 500
      cursor: context-menu
  &__controls
    display: flex
    flex-direction: column
    align-items: center
  &__title
    font-size: 16px
    margin-left: 16px
    max-width: 90%
    color: $WHITE
    cursor: context-menu
    word-break: break-word
  &__progress
    width: 40px
    height: 40px
    padding: 5px
    display: flex
    align-items: center
    justify-content: center
  &__start
    display: flex
    align-items: center
