@import "src/variables"

.toast-container
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  flex-direction: column
  z-index: 100
  padding-top: 70px
  pointer-events: none
  &__item
    min-height: 45px
    min-width: 250px
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 5px 16px
    pointer-events: all
    margin-bottom: 16px
    span
      font-size: 16px
      font-weight: 500
      margin-right: 16px
      color: $WHITE

