@import "src/variables"

.colors
  display: flex
  align-items: center
  width: 100%
  padding: 16px 0 0 0
  flex-wrap: wrap
  &__item
    min-width: 34px
    min-height: 34px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    transition: all 200ms linear
    border-radius: 100%
    margin-right: 12px
    margin-bottom: 12px
    &:hover
      transform: scale(1.1)
