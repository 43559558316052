@import "src/variables"

.activity-card
  padding: 16px 24px 8px
  border-radius: 6px
  flex-direction: column
  min-width: 350px
  max-width: 450px
  margin-bottom: 16px
  &__title
    font-size: 18px
    font-weight: 500
    display: block
    font-family: Roboto, monospace
    margin-bottom: 18px
  &__task
    display: block
    font-size: 18px
    color: $WHITE
    transition: all 200ms linear
    margin-bottom: 18px
    word-break: break-word
  &__divider
    width: 100%
    height: 1px
    border-radius: 10px
  &__info
    display: flex
    align-items: center
    justify-content: space-around
    padding: 18px 0 8px 0


