@import "src/variables"

.modal
  width: 100%
  height: 100%
  position: absolute
  z-index: 101
  display: flex
  align-items: center
  justify-content: center
  backdrop-filter: blur(3px)
  pointer-events: unset
  span
    cursor: default
    user-select: none
  &__content
    min-width: 380px
    min-height: 190px
    max-height: 250px
    border-radius: 6px
    padding: 20px
    display: flex
    flex-direction: column
    pointer-events: unset
  &__title
    color: $WHITE
    font-size: 20px
    font-weight: 700
    display: block
    margin-bottom: 16px
  &__description
    display: block
    color: $GRAY_7
    font-size: 18px
    font-weight: 500
  &__buttons
    margin-top: auto
    display: flex
    flex-direction: row
