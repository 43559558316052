@import "src/variables"

.loader
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  &__content
    width: 30%
    height: 30%
