@import "src/variables"
.settings-menu
  width: 100%
  height: 100%

.settings
  position: absolute
  z-index: 99
  width: 100%
  height: 100%
  display: flex
  &__content
    width: 100%
    margin-top: 55px
    height: 100%
    padding: 18px 22px
    overflow: scroll
  &__container
    width: 23%
    min-width: 380px
    height: 100%
    backdrop-filter: blur(10px)
    z-index: 20
  &__backdrop
    z-index: 10
    position: absolute
    width: 100%
    height: 100%
