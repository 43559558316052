@import "src/variables"

.settings-section
  margin-bottom: 24px
  &__separator
    width: 100%
    height: 1px
    border-radius: 6px
    margin-top: 16px
  &__title
    display: block
    margin-bottom: 12px
    font-weight: 500
    font-size: 16px
