@import "src/variables"

.time-progress
  width: 32px
  height: 32px
  border-radius: 50%
  padding: 4px
  display: flex
  align-items: center
  &__finish
    top: 5px
    left: 18%
    position: absolute
.timer
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-width: 60px
  position: relative
  &__text
    font-size: 16px
    margin-top: 3px
    font-weight: 500
