@import "src/variables"

.users
  display: flex
  flex-direction: column
  flex-wrap: wrap
  justify-content: center
  align-items: flex-start
.user
  --size: 46px
  --result-size: 48px
  display: flex
  width: 90px
  align-items: center
  justify-content: center
  flex-direction: column
  margin-bottom: 12px
  position: relative
  &__leader
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    z-index: 10
    top: -12px
    left: 33%
  &__name
    font-family: "Roboto",system-ui
    font-size: 16px
    text-align: center
    color: $WHITE
  &__name-shortcuts
    font-size: 16px
    font-weight: 400
    color: $WHITE
  &__check
    @include rounded(var(--size), $GREEN_1)
    position: absolute
  &__voting
    @include rounded(var(--result-size), $GRAY_10)
    position: absolute
    z-index: 0
  &__result
    @include rounded(var(--result-size), $GRAY_10)
    position: absolute
    z-index: 1
    span
      font-weight: 700
      font-size: 16px
      color: $YELLOW
  &__avatar
    position: relative
    @include rounded(var(--size), #2C90C9)
    transition: background-color 200ms
    margin-bottom: 8px
