@import "src/variables"

.app-input
  width: 100%
  input
    box-sizing: border-box
    height: 48px
    padding: 0 20px
    border-radius: 6px
    width: 100%
    font-size: 16px
    color: $GRAY_7
    caret-color: $YELLOW
    font-family: "Roboto", monospace
    font-weight: 500
    &:focus
      outline: none
    &::-webkit-input-placeholder
      color: var(--placeholder-color)
      font-family: "Roboto", monospace
    &::-webkit-inner-spin-button
      -webkit-appearance: none
