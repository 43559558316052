@import "src/variables"

.rooms-wrapper
  width: 100%
  height: 100%
  position: relative
.rooms
  width: 100%
  height: 100%
  padding: 107px 5px 70px 20px
  display: flex
  flex-direction: row
  flex-wrap: wrap
  align-content: flex-start
  &__empty
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
  &__modal-inputs
    padding: 8px 0 22px
.room-item
  transform: none
  width: 310px
  height: 110px
  border-radius: 8px
  padding: 16px
  margin: 0 20px 20px 0
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  position: relative
  &--creator
    border: 1px solid $YELLOW
  &__background
    position: absolute
    width: 100%
    height: 100%
    left: 0
    z-index: -1
    border-radius: 8px
    backdrop-filter: blur(3px)
  &__info
    display: flex
    flex-direction: column
    margin-right: 16px
  &__statuses
    display: flex
    flex-direction: row
  &__leading
    margin-right: 10px
    padding-top: 1px
  &__buttons
    display: flex
    flex-direction: row
    justify-content: space-between
  &__title
    color: $YELLOW
    font-weight: 700
    font-size: 18px
    margin-bottom: 8px
  &__description
    display: block
    color: $GRAY_7
    font-weight: 700
    font-size: 14px
    text-overflow: ellipsis
    display: -webkit-box
    overflow: hidden
    word-break: break-word
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
