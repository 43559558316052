@import "src/variables"

.icon-button
  border-radius: 50px
  border: none
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  &:hover
    transition: background-color 400ms
  button
    background-color: $TRANSPARENT
