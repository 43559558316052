@import "src/variables"

.auth
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: center
  background-color: $BLACK_1
  overflow: hidden
  position: relative

  &__logo
    font-family: "Space Grotesk", sans-serif
    margin-bottom: 36px
    &-title
      color: white
      display: block
      text-align: center
      font-size: 36px
      line-height: 0.4
    &-subtitle
      color: white
      display: block
      text-align: center
      font-size: 72px
      font-weight: 700

  &__content
    width: 14%
    min-width: 350px
  &__wrapper
    &--first
      animation-duration: 700ms
      animation-name: slidein
    &--second
      animation-duration: 700ms
      animation-name: slidein
  &__fields
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
  &__buttons
    display: flex
    flex-direction: row
    width: 100%

.card
  position: absolute
  &--left
    left: -110px
    top: -150px
    transform: rotate(-150deg)
  &--right
    right: -110px
    bottom: -150px
    transform: rotate(20deg)


@keyframes slidein
  from
    transform: scale(0.7)
  to
    transform: scale(1)