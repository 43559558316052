.container
  width: 100%
  height: 100vh
  display: flex
  flex-direction: row
  padding: 0 5px 70px 20px
.users-container
  padding-top: 107px
  display: flex
  max-height: 75%
  height: 100%
  width: 10%
  align-self: center
  min-width: 130px
.main-container
  padding-top: 107px
  width: 73%
  display: flex
  height: 100%
  min-width: 400px
  align-items: center
  flex-direction: column
  &__empty
    width: 350px
    height: 350px
    align-self: center
  &__content
    height: 88%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
.tasks-container
  min-width: 350px
  width: 17%

