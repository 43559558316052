@import "variables"

/* Указываем box sizing

*
  box-sizing: border-box

  &::before, &::after
    box-sizing: border-box

/* Убираем внутренние отступы

ul[class], ol[class]
  padding: 0

/* Убираем внешние отступы

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd
  margin: 0

/* Выставляем основные настройки по-умолчанию для body

body
  min-height: 100vh
  scroll-behavior: smooth
  text-rendering: optimizeSpeed
  line-height: 1.5

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class

ul[class], ol[class]
  list-style: none

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей

a:not([class])
  text-decoration-skip-ink: auto

/* Упрощаем работу с изображениями

img
  max-width: 100%
  display: block

/* Указываем понятную периодичность в потоке данных у article

article > * + *
  margin-top: 1em

/* Наследуем шрифты для инпутов и кнопок

input, button, textarea, select
  font: inherit

.app
  width: 100%
  height: 100vh
  max-height: 100vh
  font-family: Roboto,monospace

::-webkit-scrollbar
  width: 25px
  height: 25px
::-webkit-scrollbar-thumb
  background-color: $GRAY_9
  background-clip: content-box
  border: 10px solid transparent
  width: 3px
  height: 5px
  margin: 20px
  border-radius: 30px
::-webkit-scrollbar-thumb:hover
  background-color: $GRAY_8
::-webkit-scrollbar-track
  border-radius: 8px

