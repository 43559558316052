@import "src/variables"

.profile
  padding-top: 16px
  &__field
    display: flex
    align-items: center
    flex-direction: row
    width: 100%
    margin-bottom: 12px
