@import "src/variables"

.app-button
  position: relative
  font-weight: 500
  font-size: 16px
  height: 48px
  width: 100%
  min-width: 48px
  transition: all 200ms linear
  border-radius: 6px
  padding: 0 10px
  display: inline-flex
  align-items: center
  justify-content: center
  text-align: center
  border: none
  cursor: pointer
  outline: 0
  &.--reverse
    flex-direction: row-reverse
    justify-content: flex-end
  &.--disabled
    cursor: default
    &:focus
      background-color: $GRAY_10
    &:hover
      background-color: $GRAY_10
  span
    color: red
    margin-right: 10px
