@import "src/variables"

.cards
  display: flex
  max-width: 80%
  padding: 10px 20px 5px
  margin-top: auto
  align-items: center
  flex-direction: row
  overflow-x: scroll
  min-height: 125px
  &__card
    height: 80px
    width: 55px
    min-width: 55px
    background-color: #D99236
    display: flex
    align-items: center
    justify-content: center
    margin-right: 10px
    border-radius: 6px
    cursor: pointer
    transition: all 200ms linear
    &--selected
      transform: translateY(-10px)
    &:hover
      transform: translateY(-10px)
    span
      font-size: 20px
      font-weight: 700
      color: $WHITE
