@import "src/variables"

.header
  width: 100%
  height: 55px
  display: flex
  flex-direction: row
  align-items: center
  padding: 0 16px
  top: 0
  position: absolute
  z-index: 100
  h1
    font-size: 20px
    font-weight: 700
    line-height: 0
    color: $WHITE
    font-family: "Roboto",system-ui
