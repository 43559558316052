@import "src/variables"

.progress
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-width: 60px
  &__text
    font-size: 16px
    margin-top: 3px
    font-weight: 500
