@import "src/variables"

.switch
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 0
  cursor: pointer
  &__container
    width: 45px
    height: 26px
    padding: 2px
    border-radius: 40px
    transition: background-color 0.3s linear
  &__dot
    width: 22px
    height: 22px
    border-radius: 40px
    background-color: $WHITE
  &__text
    color: $WHITE
    font-size: 16px
    max-width: 80%
    font-weight: 500
